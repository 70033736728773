import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { LanguageContext } from '../../../context/LanguageContext'
import { Node__Awards, Query } from '../../../types/generated'
import { urlSafeBase64 } from '../../../utils'

export const ParagraphAwardsListingFields = graphql`
  fragment ParagraphAwardsListingFields on paragraph__awards_listing {
    __typename
    id
    field_title
  }
`

const ParagraphAwardListing = () => {
  const { langcode } = useContext(LanguageContext)

  const data = useStaticQuery<Query>(graphql`
    query AwardsListingParagraphQuery {
      allNodeAwards(sort: { fields: title, order: ASC }) {
        nodes {
          ...NodeAwardsFields
        }
      }
    }
  `)

  const allNodeAwardsNodes = data?.allNodeAwards?.nodes
  // @todo: remove any type
  const currentNodes = allNodeAwardsNodes?.filter(
    (award: Node__Awards) => award.langcode === langcode,
  )

  return (
    <section className="max-w-5xl mx-auto flex justify-center" aria-labelledby="Awards-heading">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-10">
        {currentNodes.map((award: any) => {
          // eslint-disable-next-line max-len
          const image =
            award?.relationships?.field_search_cta_image?.relationships?.field_media_image.uri
              .url ??
            award?.relationships?.field_icon_image?.relationships?.field_media_image?.uri?.url ??
            '/sites/default/files/2021-08/chrome-outlined.png'

          return (
            <div key={award.title} className="flex flex-col p-2 justify-between items-center">
              <Link to={award.path.alias} className="h-24 w-24">
                <img
                  loading="lazy"
                  width={96}
                  height={96}
                  src={`https://discover-admin.sandiegounified.org/image-transformation-api/${urlSafeBase64(
                    image.replace('/sites/default/files/', 'public://'),
                  )}/c_scale,width_96,height_96|c_convert,extension_webp`}
                  alt={award?.relationships?.field_icon_image?.thumbnail?.alt}
                />
              </Link>
              <Link to={award.path.alias}>
                <p className="text-center mt-4 text-sdusd-blue underline">{award.title}</p>
              </Link>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default ParagraphAwardListing
